import { Link } from "react-router-dom";
import ProductItem from "../../components/product/product.component";
import ProductCategory from "../../components/product/productCategory.component";
import { products } from "../../data/products";
import "./products.styles.css";
import React from "react";
import { partners } from "../../data/partners";

const Products = () => {
  return (
    <>
      <div id="products" className="products">
        <div className="products-wrap">
          <div className="product-items">
            <div className="products-head">PRODUCTS</div>
            <div className="divider3"></div>
            <div className="products-summary">
              The featured products are a few examples of what Healix Medical
              recommends for the Malawian market. All of these instruments, with
              a few exceptions, are currently being supported in country. In the
              event that you require products not listed, please send an enquiry
              to{" "}
              <a href="emailTo:support@healixmw.tech">support@healixmw.tech</a>{" "}
              or click on the links below which will direct you to our partners
              official website
            </div>
            <h4 style={{ textAlign: "center" }}>Authorised distributors of </h4>
            <div id="partners" className="partners-logos">
              {partners.map((partner) => (
                <a
                  href={partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="partner"
                  id={partner.id}
                >
                  <img src={partner.logo} alt={partner?.alt} />
                  <span className="caption">{partner.caption} </span>
                </a>
              ))}
            </div>
            <div className="divider3"></div>
            {products.map((product) => (
              <ProductItem
                productImage={product.image}
                name={product.name}
                category={product.category}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
