import React, { useEffect, useRef } from "react";
import "./about.styles.css";
import ContactCard from "../../components/cards/contactCard.component";
import AddressIcon from "../../svg/location";
import PhoneIcon from "../../svg/phone";
import ServiceCard from "../../components/cards/serviceCard.component";
import PersonIcon from "../../svg/person";
import { services } from "../../data/services";
import OurteamCard from "../../components/cards/ourTeam.component";
import { members } from "../../data/teams";

import { motion, useInView, useAnimation, delay } from "framer-motion";
import { Link } from "react-router-dom";
const ContactUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div id="about" className="about">
      <motion.div
        className="about-wrapper"
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1 }}
        ref={ref}
      >
        <div className="about-header page-header">About Us </div>

        <div className="about1">
          <div className="about-img">
            <img
              src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622394/healix/official%20photos/INF09414_dydn8e.jpg"
              alt="about-imgs"
            />
          </div>
          <div className="about1-text">
            <div className="heading">Innovative Diagnostic Solutions</div>
            <p>
              Healix Medical works with our clients to provide the best in class
              diagnostic solutions. Healix has authorized distributor
              partnerships with internationally recognized brands, and Medical
              has trained our technical and application specialist teams at the
              highest level with these companies. Through using the products and
              services that Healix provides you can reduce your long term costs
              of diagnostic support and services.
            </p>
          </div>
        </div>
        <div className="about2">
          <div className="left-section">
            <div className="heading">Our Services</div>
            <div>
              Healix Medical provides diagnostic equipment, technical support,
              training and solutions to research labs, public and private
              hospitals. Healix represents internationally recognized companies
              who assist in advancing laboratory services in Malawi .
            </div>{" "}
            {services.map((service) => (
              <ServiceCard
                serviceIcon={<service.icon />}
                service={service.service}
                serviceDescription={service.text}
              />
            ))}
          </div>

          <div className="right-section">
            <img
              src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622372/healix/official%20photos/INF09290_gs44be.jpg"
              alt="about-imgs"
            />
          </div>
        </div>
        <div className="about3">
          <div className="heading">Our Team</div>
          <div className="contents">
            {members.map((member) => (
              <div className="team-members">
                <OurteamCard
                  image={member.image}
                  name={member.name}
                  position={member.position}
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactUs;
