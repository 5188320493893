import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Header from "./components/header/header.component";
import Home from "./pages/home/home.page";
import About from "./pages/about/about.page";
import Testimonials from "./pages/testimonials/testimonials.page";
import ContactUs from "./pages/contact/contact.page";
import FeaturedProducts from "./pages/FeaturedProducts/FeaturedProducts.page";
import Products from "./pages/products/products.page";
import Category from "./pages/products/categories.page";
import Product from "./pages/product/product.page";
import Services from "./pages/services/services.page";
import Footer00 from "./components/footer/footer00.component";
import ScrollToTop from "./components/scroolToTop/scrollToTop.component";
import NotFoundPage from "./components/errorHandlers/notFoundPage.component";

function App() {
  return (
    <Router>
      <header>
        <Header />
      </header>
      <main>
        <div className="" style={{ zIndex: "99", position: "rel" }}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:category" element={<Category />} />
            <Route path="/product/:productName" element={<Product />} />
            <Route path="/services" element={<Services />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </main>
      <footer>
        <Footer00 />
      </footer>
    </Router>
  );
}

export default App;
