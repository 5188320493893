import React from 'react';

const BackToIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height="24"
      id="arrow-back-ios"
    >
      <path fill="none" d="M0 0h24v24H0V0z" opacity=".87"></path>
      <path d="M17.51 3.87 15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"></path>
    </svg>
  );
};

export default BackToIcon;
