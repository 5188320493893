import React from 'react';

const TechSupport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 512 512"
      viewBox="0 0 512 512"
      id="technical-support"
      width={60}
    >
      <path
        fill="#f7f7f7"
        d="M375.6,159.7h-20.3c-2.6-12.4-7.5-23.9-14.1-34.1l14.3-14.3c3.5-3.5,3.5-9.2,0-12.9l-17-17
    c-3.5-3.5-9.2-3.5-12.9,0l-14.3,14.3c-10.2-6.7-21.8-11.6-34.1-14.1V61.2c0-5-4.1-9.1-9.1-9.1H244c-5,0-9.1,4.1-9.1,9.1v20.3
    c-12.4,2.6-23.9,7.5-34.1,14.1l-14.3-14.3c-3.5-3.5-9.2-3.5-12.9,0l-17,17c-3.5,3.5-3.5,9.2,0,12.9l14.3,14.3
    c-6.7,10.2-11.6,21.8-14.1,34.1h-20.3c-5,0-9.1,4.1-9.1,9.1v24.1c0,5,4.1,9.1,9.1,9.1h20.4c2.6,12.4,7.5,23.9,14.1,34.1l-14.3,14.3
    c-3.5,3.5-3.5,9.2,0,12.9l17,17c3.5,3.5,9.2,3.5,12.9,0l14.3-14.3c10.2,6.7,21.8,11.6,34.1,14.1v20.3c0,5,4.1,9.1,9.1,9.1h24.1
    c5,0,9.1-4.1,9.1-9.1v-20.3c12.4-2.6,23.9-7.5,34.1-14.1l14.3,14.3c3.5,3.5,9.2,3.5,12.9,0l17-17c3.5-3.5,3.5-9.2,0-12.9
    l-14.3-14.3c6.7-10.2,11.6-21.8,14.1-34.1h20.3c4.9,0,9-4.1,9-9.1v-24.1C384.7,163.8,380.6,159.7,375.6,159.7z M256.1,255.9
    c-41.5,0-75.2-33.6-75.2-75.2c0-41.5,33.6-75.2,75.2-75.2c41.5,0,75.2,33.6,75.2,75.2C331.2,222.3,297.6,255.9,256.1,255.9z"
      ></path>
      <path
        fill="#7cc250"
        d="M302.5,166.5h-32.2v-32.2c0-4-3.2-7.2-7.2-7.2h-14.3c-4,0-7.2,3.2-7.2,7.2v32.2h-32.2c-4,0-7.2,3.2-7.2,7.2
    V188c0,4,3.2,7.2,7.2,7.2h32.2v32.2c0,4,3.2,7.2,7.2,7.2h14.3c4,0,7.2-3.2,7.2-7.2v-32.2h32.2c4,0,7.2-3.2,7.2-7.2v-14.3
    C309.7,169.7,306.5,166.5,302.5,166.5z"
      ></path>
      <path
        fill="#e5bb98"
        d="M350.9,349.4l-58.4,58.4l-29.9-34.9l48.3-48.1c10.9-11.1,27.9-10.4,38.1,1.4l2.6,3.1
        C356.5,335.1,356.2,344.1,350.9,349.4z"
      ></path>
      <path
        fill="#f4c69d"
        d="M387,349.4l-86.6,89.1l-29.9-34.9l76.4-78.8c10.9-11.1,27.9-10.4,38.1,1.4l2.6,3.1
        C392.5,335.1,392.2,344.1,387,349.4z"
      ></path>
      <path
        fill="#fed0a2"
        d="M325.5,445.3c-20.1,14.6-32.4,17.8-65.2,10.4c0,0-62.9-7.7-66-7.7h-64.8V335.7h18.3
        c32.4-12.1,66.9,8.4,66.9,8.4h45c16.3,0,29.5,14.4,29.5,32.1c0,3.7-1.2,7-3.1,9.7c0,0.1-0.1,0.2-0.2,0.3c-2.1,2.7-4.9,4.6-8.2,5.3
        c0,0,0,0,0,0l28.6,0.2c7.2,0.1,14.2-2.8,19.6-8.1l62.8-58.7c10.9-11.1,27.9-10.4,38.1,1.4l2.6,3.1c4.9,5.7,4.6,14.7-0.6,20.1
        L325.5,445.3z"
      ></path>
      <path
        fill="#f4c69d"
        d="M286,385.9c0,0.1-0.1,0.2-0.2,0.3c-2,2.7-4.9,4.6-8.1,5.3h-62.2c-1.4,0-2.6-1.2-2.6-2.8
        c0-1.5,1.1-2.8,2.6-2.8H286z"
      ></path>
      <path
        fill="#f7f7f7"
        d="M140.2,451.9h-29.7c-5.4,0-9.7-4.3-9.7-9.7V338.8c0-5.4,4.3-9.7,9.7-9.7h29.7c5.4,0,9.7,4.3,9.7,9.7v103.4
        C149.9,447.6,145.5,451.9,140.2,451.9z"
      ></path>
      <path
        fill="#528bc9"
        d="M123.8,459.9H90.2c-6.1,0-11-4.9-11-11V332.1c0-6.1,4.9-11,11-11h33.6c6.1,0,11,4.9,11,11v116.8
        C134.7,455,129.8,459.9,123.8,459.9z"
      ></path>
      <circle cx="107" cy="349" r="13.4" fill="#f7f7f7"></circle>
    </svg>
  );
};

export default TechSupport;
