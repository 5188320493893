export const testimonials = [
  {
    profile_picture:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/c_thumb,w_200,g_face/v1679072491/samples/people/smiling-man.jpg",
    profile_name: "Anthony",
    testimony:
      "Our customers’ feedback is very important in helping us get it right. We listen and place a huge value in what they say about our services. Healix Medical is committed to to excellence and here is what our customers say about us.",
    position: "Chief Executive Office",
  },
  {
    profile_picture:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/c_thumb,w_200,g_face/v1679072491/samples/people/smiling-man.jpg",
    profile_name: "Mike",
    testimony:
      "Our customers’ feedback is very important in helping us get it right. We listen and place a huge value in what they say about our services. Healix Medical is committed to to excellence and here is what our customers say about us..",
    position: "Operations Manager",
  },
  {
    profile_picture:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/c_thumb,w_200,g_face/v1679072491/samples/people/smiling-man.jpg",
    profile_name: "John Doe",
    testimony:
      "Our customers’ feedback is very important in helping us get it right. We listen and place a huge value in what they say about our services. Healix Medical is committed to to excellence and here is what our customers say about us..",
    position: "Operations Manager",
  },
  {
    profile_picture:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/c_thumb,w_200,g_face/v1679072491/samples/people/smiling-man.jpg",
    profile_name: "John Doe",
    testimony:
      "Our customers’ feedback is very important in helping us get it right. We listen and place a huge value in what they say about our services. Healix Medical is committed to to excellence and here is what our customers say about us..",
    position: "Operations Manager",
  },
  {
    profile_picture:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/c_thumb,w_200,g_face/v1679072491/samples/people/smiling-man.jpg",
    profile_name: "John Doe",
    testimony:
      "Our customers’ feedback is very important in helping us get it right. We listen and place a huge value in what they say about our services. Healix Medical is committed to to excellence and here is what our customers say about us..",
    position: "Operations Manager",
  },
  {
    profile_picture:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/c_thumb,w_200,g_face/v1679072491/samples/people/smiling-man.jpg",
    profile_name: "John Doe",
    testimony:
      "Our customers’ feedback is very important in helping us get it right. We listen and place a huge value in what they say about our services. Healix Medical is committed to to excellence and here is what our customers say about us..",
    position: "Operations Manager",
  },
];
