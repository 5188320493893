import React, { useEffect, useRef } from "react";
import "./card.styles.css";
import RightArrow from "../../svg/right-arrow";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation } from "framer-motion";

const HomeCard = ({ title, color }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); //once allows it to fire the animation once even if you scroll back up itwont fire again

  //where everthere is animate replace with controls
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <motion.div
      className="card"
      style={{ background: `${color}` }}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1.5 }}
      ref={ref}
    >
      <div className="card-wrap">
        <div className="card-header">{title} </div>
        <Link to={"/services"} class="title  hover-opacity ">
          See More <RightArrow fill="#fff" />
        </Link>
      </div>
    </motion.div>
  );
};

export default HomeCard;
