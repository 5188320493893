import React from "react";

const NotFound = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      id="comment-exclamation"
    >
      <defs>
        <linearGradient
          id="a"
          x1="4.51"
          x2="20.93"
          y1="4.51"
          y2="20.93"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#13cfcf"></stop>
          <stop offset=".4" stop-color="#2cb4fe"></stop>
          <stop offset="1" stop-color="#2285dd"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="5.05"
          x2="21.46"
          y1="3.98"
          y2="20.39"
          //   xlink:href="#a"
        ></linearGradient>
        <linearGradient
          id="c"
          x1="2.3"
          x2="18.71"
          y1="6.73"
          y2="23.14"
          //   xlink:href="#a"
        ></linearGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M18.5,21.25h-6.5c-5.1,0-9.25-4.15-9.25-9.25S6.9,2.75,12,2.75s9.25,4.15,9.25,9.25v6.5c0,1.52-1.23,2.75-2.75,2.75ZM12,4.25c-4.27,0-7.75,3.48-7.75,7.75s3.48,7.75,7.75,7.75h6.5c.69,0,1.25-.56,1.25-1.25v-6.5c0-4.27-3.48-7.75-7.75-7.75Z"
      ></path>
      <path
        fill="url(#b)"
        d="M13,8l-.27,6.32c-.04,.91-1.42,.91-1.46,0,0,0-.27-6.32-.27-6.32-.03-1.37,2.03-1.37,2,0h0Z"
      ></path>
      <circle cx="12" cy="16.43" r=".63" fill="url(#c)"></circle>
    </svg>
  );
};

export default NotFound;
