import React from "react";

const Checkup = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="checkup">
      <path
        fill="#ffffff"
        d="M61.02 40.32v-1.04c0-6.75-5.49-12.23-12.24-12.23s-12.24 5.48-12.24 12.23v5.91c0 5.71-4.64 10.36-10.36 10.36-5.71 0-10.36-4.65-10.36-10.36V32.93c6.46-1.06 11.41-6.67 11.41-13.42V6.24c0-1.22-.98-2.21-2.21-2.21h-5.24a2.21 2.21 0 0 0 0 4.42h3.03V19.5c0 5.07-4.12 9.2-9.2 9.2-5.08 0-9.2-4.13-9.2-9.2V8.45h3.02a2.21 2.21 0 0 0 0-4.42H2.21C.98 4.03 0 5.02 0 6.24v13.27c0 6.76 4.94 12.36 11.41 13.42v12.25c0 8.15 6.62 14.79 14.78 14.79 8.15 0 14.78-6.63 14.78-14.79v-5.91c0-4.31 3.51-7.81 7.82-7.81 4.31 0 7.82 3.5 7.82 7.81v1.04a5.195 5.195 0 1 0 7.4 4.7 5.215 5.215 0 0 0-2.99-4.69zm-2.21 5.47c-.43 0-.78-.35-.78-.78s.34-.77.78-.77c.42 0 .77.35.77.77s-.35.78-.77.78z"
      ></path>
      <path
        fill="#f6b64e"
        d="M59.58 45.01c0 .42-.34.78-.77.78s-.78-.35-.78-.78.34-.77.78-.77c.42 0 .77.35.77.77z"
      ></path>
    </svg>
  );
};

export default Checkup;
