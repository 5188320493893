import React from "react";
import Testimonial from "../../components/testimonials/testimonial.component";
import "./testimonials.styles.css";
import { testimonials } from "../../data/testimonials";
const Testimonials = () => {
  return (
    <div className="testimonials">
      <div className="">
        <div className="testimonials-wrap">
          <div className="testimial-header page-header">Testimonials </div>
          <div className="testimonial-title">What our clients say</div>
          <div className="description">
            Healix Medical customers’ feedback is very important in helping us
            get it right. Healix Medical listens and places a huge value in what
            they say about our services. Healix Medical is committed to to
            excellence and here is what our customers say about us.{" "}
          </div>
          <div className="testimonials-content">
            {testimonials.map((testimony, i) => (
              <Testimonial
                profile_picture={testimony.profile_picture}
                profile_name={testimony.profile_name}
                testimony={testimony.testimony}
                position={testimony.position}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
