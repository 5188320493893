import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const ProductDetailComponent = ({
  productImage,
  productName,
  productSummary,
  productFullText,
}) => {
  return (
    <div className="view-product-wrap">
      <div className="product-wrapper">
        <div className="view-image">
          <img src={productImage} alt="" />
        </div>
        <div className="product-summary">
          <div className="product-name">{productName}</div>
          <p>{productSummary}</p>
          <Link to="/contact">
            <motion.button
              className="inquire-btn"
              initial={{
                opacity: 0,
                x: 75,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
              }}
            >
              Get quote
            </motion.button>
          </Link>
        </div>
      </div>
      <div className="product-description">Product Description</div>
      <motion.div
        className="full-description"
        initial={{
          opacity: 0,
          y: 75,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.5,
          ease: "easeIn",
        }}
      >
        <div className="description-text">{productFullText}</div>
      </motion.div>
    </div>
  );
};

export default ProductDetailComponent;
