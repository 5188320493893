import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const OurteamCard = ({ image, name, position }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <motion.div
      className="team-item"
      variants={{
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1 }}
      ref={ref}
    >
      <img src={image} alt="about-imgs" />
      <div className="member-name">{name}</div>
      <span className="position">{position}</span>
    </motion.div>
  );
};

export default OurteamCard;
