import React, { useRef } from "react";
import { Link } from "react-router-dom";
import DropDwonArrow from "../../svg/dropdownArrow";
import RightArrow from "../../svg/right-arrow";
import CloseIcon from "../../svg/close";
import BackToIcon from "../../svg/backTo";
import useClickOutside from "../../helpers/clickOutside.helper";

const MobileMenu = ({
  setShowMobileMenu,
  setShowMobileDropdown,
  handleClose,
  showMobileMenu,
}) => {
  const mobileMenu = useRef(null);
  useClickOutside(mobileMenu, () => {
    setShowMobileMenu(false);
  });
  return (
    <div
      className={`mobile-menu ${
        !showMobileMenu ? "mobile-menu-close" : "mobile-menu-open"
      }`}
      ref={mobileMenu}
    >
      <div className="mobile-menu-btns">
        <span
          className="close-menu"
          onClick={() => {
            setShowMobileMenu(false);
          }}
        >
          <CloseIcon />
        </span>
      </div>
      <div className="mobile-wrap">
        <div className="nav">
          <ul className="nav-items">
            <li className="nav-link">
              <Link to={"/#banner"} className="nav-link" onClick={handleClose}>
                Home
              </Link>
            </li>
            <li
              className="nav-link"
              onClick={() => setShowMobileDropdown(true)}
            >
              <Link to={"/products"} className="nav-link products-dropdown">
                Featured Products <RightArrow fll="#000" />
              </Link>
            </li>
            <li className="nav-link">
              <Link to={"/services"} className="nav-link" onClick={handleClose}>
                Services
              </Link>
            </li>
            {/* <li className="nav-link">
              <Link
                to={"/testimonials"}
                className="nav-link"
                onClick={handleClose}
              >
                Testimonials
              </Link>
            </li> */}
            <li className="nav-link">
              <Link to={"/about"} className="nav-link" onClick={handleClose}>
                About us
              </Link>
            </li>
            <li className="nav-link">
              <Link
                to={"/contact/#contact"}
                className="nav-link"
                onClick={handleClose}
              >
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
