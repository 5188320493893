import React, { useEffect, useRef } from "react";
import "./banner.styles.css";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation } from "framer-motion";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div id="banner" className="banner-container">
      <motion.div
        class="banner"
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1.5 }}
        ref={ref}
      >
        <img
          class="banner-image"
          src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622378/healix/official%20photos/INF09295_ijsjmn.jpg"
        />
        <motion.div
          class="professionals"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.5, delay: 2 }}
        >
          Innovative Diagnostic
          <br />
          Solutions{" "}
        </motion.div>{" "}
        <div class="more-details">
          <Link to="/services" class="text-wrapper">
            More details
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default Banner;
