import "./footer00.styles.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../mobileMenu/mobileMenu.component";
import Menu from "../../svg/menu";
import PhoneIcon from "../../svg/phone";
import AddressIcon from "../../svg/location";
import Email from "../../svg/email";
import InputComponent from "../inputs/inputField.component";
import Arrow from "../../svg/arrow";
import XIcon from "../../svg/socials/x";
import InstagramIcon from "../../svg/socials/insta";
import FacebookIcon from "../../svg/socials/facebook";
import Logo from "../../assets/images/logo.png";
import Time from "../../svg/footer/time";
import Hotline from "../../svg/footer/hotline";
import Emaill from "../../svg/footer/email";
import LinkedIn from "../../svg/socials/linkedin";
import { partners } from "../../data/partners";

const Footer00 = () => {
  return (
    <div className="main-footer">
      <div className="top-footer partners">
        {partners.map((partner) => (
          <a
            href={partner.link}
            target="_blank"
            rel="noopener noreferrer"
            className="partner"
            id={partner.id}
          >
            <img src={partner.logo} alt={partner?.alt} />
          </a>
        ))}
      </div>
      <div className="divider2"></div>
      <div className="middle-footer">
        <div className="left-middle">
          <div className="information">
            <h3 className="footer-header">Information</h3>
            <ul>
              <li>
                <a href="/contact/#faqs">FAQs</a>
              </li>
              <li>
                <a href="/contact/#contact-form">Request a quote</a>
              </li>
              <li>
                <a href="/products/#products">Our partners</a>
              </li>
              <li>
                <a href="/products/#products">Products</a>
              </li>
            </ul>
          </div>
          <div className="support">
            <h3 className="footer-header">Support</h3>
            <div className="contact-support">
              <div className="contact-content">
                {" "}
                <Time className />
                <span className="cont-header"> Mo - Fr</span>{" "}
              </div>{" "}
              <span>8AM - 4:30PM</span>{" "}
            </div>
            <div className="contact-support">
              <div className="contact-content">
                {" "}
                <Hotline className />{" "}
                <span className="cont-header">Hotline</span>
              </div>{" "}
              <span>+265 888 729 116</span>{" "}
            </div>
            <div className="contact-support">
              <div className="contact-content">
                <Emaill className />
                <span className="cont-header">email</span>
              </div>{" "}
              <span className="espan">support@healixmw.tech</span>
              {/* <span className="espan">nora@healixmw.tech</span> */}
            </div>
          </div>
        </div>
        <div className="social-media">
          <h3 className="footer-header">Social Media</h3>
          <div className="socials">
            <a href="">
              {" "}
              <XIcon />
            </a>
            <a href="https://www.linkedin.com/company/healix-medical/">
              <LinkedIn />
            </a>
            <a href="">
              <FacebookIcon />
            </a>
          </div>
          <div className="divider"></div>
          <div className="signup-newsl">
            <h4>Sign up</h4>
            <p>Sign up for our newsletters to get our latest news</p>
            <div className="subscribe">
              <input type="emal" placeholder="Type email" />{" "}
              <button className="newsletter-btn">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div className="divider2"></div>
      <div className="bottom-footer">
        <p>&copy; 2024 Healix Medical. All Right Reserved</p>
      </div>
    </div>
  );
};

export default Footer00;
