import React from "react";

const Hotline = ({ width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" id="hours">
      <path
        fill="#5fbdff"
        d="m168.99,106.37c4.59-2.65,6.17-8.53,3.52-13.12l-27.96-48.41c-2.66-4.6-8.54-6.17-13.12-3.52-4.59,2.65-6.17,8.53-3.52,13.12l27.96,48.41c1.78,3.08,5.01,4.8,8.32,4.8,1.63,0,3.28-.41,4.79-1.28Zm-60.97,59.75c2.65-4.59,1.08-10.46-3.52-13.12l-48.41-27.95c-4.58-2.65-10.46-1.08-13.12,3.52-2.65,4.59-1.08,10.46,3.52,13.12l48.41,27.95c1.51.87,3.16,1.28,4.79,1.28,3.32,0,6.54-1.72,8.32-4.8Zm-22.92,82.23c0-5.3-4.29-9.6-9.6-9.6H19.6c-5.31,0-9.6,4.3-9.6,9.6s4.29,9.6,9.6,9.6h55.9c5.31,0,9.6-4.3,9.6-9.6Z"
      ></path>
      <path
        fill="#96efff"
        d="m54.44,372.08l48.42-27.95c4.59-2.65,6.17-8.52,3.52-13.12-2.66-4.59-8.55-6.16-13.12-3.52l-48.42,27.95c-4.59,2.65-6.17,8.52-3.52,13.12,1.78,3.08,5.01,4.8,8.33,4.8,1.63,0,3.28-.41,4.79-1.28Zm87.24,81.42l27.96-48.41c2.65-4.59,1.08-10.46-3.52-13.12-4.58-2.65-10.46-1.08-13.12,3.52l-27.96,48.41c-2.65,4.59-1.08,10.46,3.52,13.12,1.51.87,3.16,1.28,4.79,1.28,3.32,0,6.54-1.72,8.33-4.8Zm116.26,26.9v-55.9c0-5.3-4.29-9.6-9.6-9.6s-9.6,4.3-9.6,9.6v55.9c0,5.3,4.29,9.6,9.6,9.6s9.6-4.3,9.6-9.6Zm110.62-21.73c4.59-2.65,6.17-8.53,3.52-13.12l-27.96-48.41c-2.65-4.6-8.54-6.17-13.12-3.52-4.59,2.65-6.17,8.53-3.52,13.12l27.96,48.41c1.78,3.08,5.01,4.8,8.33,4.8,1.63,0,3.28-.41,4.79-1.28Zm88.45-87.24c2.65-4.59,1.08-10.46-3.52-13.12l-48.41-27.95c-4.58-2.64-10.46-1.08-13.12,3.52-2.65,4.59-1.08,10.46,3.52,13.12l48.41,27.95c1.51.87,3.16,1.28,4.79,1.28,3.32,0,6.54-1.72,8.33-4.8Z"
      ></path>
      <path
        fill="#c5fff8"
        d="m251.66,85.1c-5.31,0-9.6-4.3-9.6-9.6V19.6c0-5.3,4.29-9.6,9.6-9.6s9.6,4.3,9.6,9.6v55.9c0,5.3-4.29,9.6-9.6,9.6Zm238.34,166.55c0-5.3-4.29-9.6-9.6-9.6h-55.9c-5.31,0-9.6,4.3-9.6,9.6s4.29,9.6,9.6,9.6h55.9c5.31,0,9.6-4.3,9.6-9.6Zm-83.26-79.15l48.42-27.95c4.59-2.65,6.17-8.52,3.52-13.12-2.66-4.59-8.55-6.16-13.12-3.52l-48.42,27.95c-4.59,2.65-6.17,8.52-3.52,13.12,1.78,3.08,5.01,4.8,8.33,4.8,1.63,0,3.28-.41,4.79-1.28Zm-59.75-67.99l27.96-48.41c2.65-4.59,1.08-10.46-3.52-13.12-4.58-2.66-10.46-1.08-13.12,3.52l-27.96,48.41c-2.65,4.59-1.08,10.46,3.52,13.12,1.51.87,3.16,1.28,4.79,1.28,3.32,0,6.54-1.72,8.33-4.8Z"
      ></path>
      <g>
        <path
          fill="#7b66ff"
          d="M197.45 286.19c2.52-4.83 7.63-11.34 15.17-19.35 12.35-12.99 21.2-24.77 26.33-35 5.22-10.46 7.87-21.35 7.87-32.36 0-14.88-3.84-26.59-11.43-34.8-7.69-8.33-18.8-12.54-33.02-12.54s-25.32 4.22-33.01 12.55c-7.58 8.21-11.43 19.92-11.43 34.8v11.63c0 5.3 4.3 9.6 9.6 9.6h15.53c5.3 0 9.6-4.3 9.6-9.6v-13.33c0-10.44 4.31-11.88 8.98-11.88 4.04 0 5.69 1.43 6.57 2.49.9 1.08 2.41 4.09 2.41 12.05 0 7.24-1.73 14.32-5.16 21.02-3.55 6.96-10.17 15.62-19.62 25.7-10.32 10.82-17.62 20.91-21.7 30-4.12 9.21-6.22 20.32-6.22 33.03v13.57c0 5.3 4.3 9.6 9.6 9.6h67.27c5.3 0 9.6-4.3 9.6-9.6v-14.57c0-5.3-4.3-9.6-9.6-9.6h-40.9c.17-4.73 1.36-9.23 3.54-13.4h0zM335.78 268.89v-104.74c0-5.3-4.3-9.6-9.6-9.6h-21.02c-3.86 0-7.34 2.31-8.84 5.86l-46.38 109.67c-.49 1.15-.74 2.38-.76 3.62l-.23 19.24c-.07 5.35 4.25 9.72 9.6 9.72h31.45c5.3 0 9.6 4.3 9.6 9.6v11.5c0 5.3 4.3 9.6 9.6 9.6h16.99c5.3 0 9.6-4.3 9.6-9.6v-21.1h2.57c5.52 0 10-4.48 10-10v-13.77c0-5.52-4.48-10-10-10h-2.57zm-50.92 0l14.74-37.25v37.25h-14.74z"
        ></path>
      </g>
    </svg>
  );
};

export default Hotline;
