import React from "react";

const Training = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="training">
      <path fill="#41ead4" d="M12 25.59V27l6 7h42V2H12v23.59z"></path>
      <path
        fill="#ffbf69"
        d="M16 41v9H4v-9l-2-.971v-8.15A4.88 4.88 0 0 1 6.879 27H17l7.772-3.533A2.283 2.283 0 0 1 28 25.545a2.284 2.284 0 0 1-1.133 1.973L18 32.686v7.343Z"
      ></path>
      <circle cx="10" cy="21" r="5" fill="#ffbf69"></circle>
      <path
        fill="#247ba0"
        d="M39 8h18v2H39zM31 14h26v2H31zM31 21h26v2H31zM31 28h26v2H31z"
      ></path>
      <path
        fill="#4f5d75"
        d="M40 62H22v-1.2a6.8 6.8 0 0 1 6.8-6.8h4.4a6.8 6.8 0 0 1 6.8 6.8Z"
      ></path>
      <circle cx="31" cy="49" r="4" fill="#4f5d75"></circle>
      <path
        fill="#ffbf69"
        d="M62 62H44v-1.2a6.8 6.8 0 0 1 6.8-6.8h4.4a6.8 6.8 0 0 1 6.8 6.8Z"
      ></path>
      <circle cx="53" cy="49" r="4" fill="#ffbf69"></circle>
    </svg>
  );
};

export default Training;
