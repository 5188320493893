import React from 'react';

const AppSupport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      id="live-chat"
      width={50}
    >
      <circle cx="32" cy="32" r="32" fill="#65c981"></circle>
      <path
        fill="#2d434f"
        d="M9.43,14.79V40.2H51.35V14.79a.87.87,0,0,0-.87-.87H10.3A.87.87,0,0,0,9.43,14.79Zm40,23.31H11.32v-22H49.45Z"
      ></path>
      <rect
        width="38.13"
        height="21.98"
        x="11.32"
        y="16.07"
        fill="#78caea"
      ></rect>
      <path
        fill="#ebebeb"
        d="M51.34,40.2v3.74a.61.61,0,0,1-.6.61H10a.61.61,0,0,1-.61-.61V40.2Z"
      ></path>
      <circle cx="30.46" cy="42.37" r="1.49" fill="#fff"></circle>
      <rect width="9.25" height=".86" x="25.83" y="44.55" fill="#dadde1"></rect>
      <path
        fill="#ebebeb"
        d="M37.63,49.24H23.28a5.74,5.74,0,0,0,2.55-3.84h9.25A5.74,5.74,0,0,0,37.63,49.24Z"
      ></path>
      <path
        fill="#dadde1"
        d="M22.86,49.24H38a.84.84,0,0,1,.84.84v0a0,0,0,0,1,0,0H22a0,0,0,0,1,0,0v0A.84.84,0,0,1,22.86,49.24Z"
      ></path>
      <path
        fill="#5dafd8"
        d="M54.57,17.43v9.84a1.47,1.47,0,0,1-1.49,1.44H44.44L38.92,33V28.72H36.57a1.46,1.46,0,0,1-1.49-1.44V17.43A1.46,1.46,0,0,1,36.57,16H53.08A1.47,1.47,0,0,1,54.57,17.43Z"
      ></path>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="17.9"
        fill="#fff"
        transform="rotate(-180 44.83 18.19)"
      ></rect>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="19.25"
        fill="#fff"
        transform="rotate(-180 44.83 19.535)"
      ></rect>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="20.6"
        fill="#fff"
        transform="rotate(-180 44.83 20.885)"
      ></rect>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="21.95"
        fill="#fff"
        transform="rotate(-180 44.83 22.235)"
      ></rect>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="23.3"
        fill="#fff"
        transform="rotate(-180 44.83 23.58)"
      ></rect>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="24.64"
        fill="#fff"
        transform="rotate(-180 44.83 24.93)"
      ></rect>
      <rect
        width="16.18"
        height=".57"
        x="36.74"
        y="25.99"
        fill="#fff"
        transform="rotate(-180 44.83 26.28)"
      ></rect>
      <path
        fill="#f8f7f7"
        d="M36.46,33.78A11.79,11.79,0,0,1,29.69,37l-.09-.28-1.68.17-.6.11a11.79,11.79,0,0,1-6.76-3.2c.14-.14.54-.45.54-.45l4.14-1.69,0,0L26.64,31l3.62,0,1.52.59,4.14,1.69S36.32,33.64,36.46,33.78Z"
      ></path>
      <path
        fill="#d4a67f"
        d="M30.8,28.51v3.23a8.07,8.07,0,0,1-2.17,1.48.36.36,0,0,1-.25,0,8.07,8.07,0,0,1-2.17-1.48V28.51l.36.42A4.56,4.56,0,0,0,27.87,30h1.29a4.56,4.56,0,0,0,1.29-1.05Z"
      ></path>
      <path
        fill="#eab78c"
        d="M33.76,25c-.28,1.55-1.42,1.32-1.39,1.44s-.42,1.62-.55,1.94a7.79,7.79,0,0,1-1,1.31l-.36.42a4.56,4.56,0,0,1-1.29,1.05H27.87a4.56,4.56,0,0,1-1.29-1.05l-.36-.42a7.79,7.79,0,0,1-1-1.31c-.14-.32-.55-1.94-.55-1.94s-1.1.11-1.39-1.44c-.21-1.18.11-1.51.36-1.61a.52.52,0,0,1,.26,0l.31-2.77,8.51-.22.44,3h.06C33.39,23.35,34,23.45,33.76,25Z"
      ></path>
      <path
        fill="#d3d3d3"
        d="M31.68 31.58a13.24 13.24 0 0 1-1.47 1.29l-.18 0h0a8 8 0 0 0 1-1.6zM27 32.89h0l-.18 0a13.24 13.24 0 0 1-1.47-1.29l.7-.29A8 8 0 0 0 27 32.89z"
      ></path>
      <path
        fill="#2d434f"
        d="M33.19,23.36h-.06l-.34,1.53h-.34l.12-3.25s-.15.17-.78.08a20.21,20.21,0,0,0-3.95-.93c-1.87-.19-2.3.53-2.3.53a10.26,10.26,0,0,1-1.08,1.31l.08,2.27h-.34l-.34-1.53a.54.54,0,0,0-.25,0c-.78-3.57,2-6.81,5.17-6.21,4,.75,4.48,2.82,4.48,2.82A8.12,8.12,0,0,1,33.19,23.36Z"
      ></path>
      <path
        fill="#f75267"
        d="M29.8,37a6.6,6.6,0,0,1-2.58,0l.59-3.51h0l.39-.59.79,0,.22.62Z"
      ></path>
      <path
        fill="#e54c66"
        d="M29.35,33.16l-.11.24,0,.08a1.47,1.47,0,0,0-1.41,0l0-.08-.11-.24.07-.19c.12-.32.39-.93.63-1a.42.42,0,0,0,.28,0c.24.12.51.73.63,1Z"
      ></path>
      <path
        fill="#9a9ea0"
        d="M31.08 31.36a8 8 0 0 1-1 1.6 10.8 10.8 0 0 1-.76.89l-.17-.45.1-.24L29.22 33c-.11-.32-.36-.93-.58-1a8.07 8.07 0 0 0 2.17-1.48l.08-.09 0 .1zM27.8 33l-.07.19.1.24-.17.45A10.8 10.8 0 0 1 26.9 33a8 8 0 0 1-1-1.6l.19-1 .08.09a8.07 8.07 0 0 0 2.17 1.48C28.16 32 27.91 32.65 27.8 33z"
      ></path>
    </svg>
  );
};

export default AppSupport;
