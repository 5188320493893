import { useParams } from 'react-router-dom';
import ProductItem from '../../components/product/product.component';
import ProductCategory from '../../components/product/productCategory.component';
import { products } from '../../data/products';
import './products.styles.css';
import React from 'react';

const Category = () => {
  const params = useParams();
  const { category } = params;

  return (
    <div className="products">
      <div className="products-header">
        {' '}
        {category.toUpperCase()} COLLECTIONS
      </div>
      <div className="products-wrap">
        <div className="product-items">
          {products
            .filter((product) =>
              product.category.toLocaleLowerCase().includes(category)
            )
            .map((filteredProduct) => (
              <ProductItem
                category={filteredProduct.category}
                productImage={filteredProduct.image}
                name={filteredProduct.name}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
