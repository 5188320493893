import React from 'react';

const Two47Support = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      id="247-support"
      width={50}
    >
      <path
        fill="#7ca3c2"
        d="M63.95 23.27c-.73-2.67-1.78-5.21-3.12-7.57-.37-.65-1.2-.84-1.86-.49-2.86 1.54-6.5 1.13-8.92-1.29-2.41-2.42-2.83-6.06-1.28-8.92.36-.66.16-1.49-.49-1.86A32.82 32.82 0 0 0 40.9.08c-.73-.2-1.46.27-1.68.99-.95 3.1-3.83 5.36-7.24 5.36s-6.3-2.26-7.24-5.36c-.22-.73-.95-1.2-1.68-.99-2.6.73-5.08 1.76-7.38 3.06-.65.37-.84 1.2-.49 1.86 1.54 2.86 1.13 6.5-1.29 8.92s-6.05 2.83-8.92 1.29c-.66-.36-1.49-.16-1.86.49a32.82 32.82 0 0 0-3.06 7.38c-.2.73.27 1.46.99 1.68a7.57 7.57 0 0 1-.01 14.48c-.73.22-1.2.95-.99 1.68.73 2.6 1.76 5.08 3.06 7.38.37.65 1.2.84 1.86.49 2.86-1.54 6.5-1.13 8.92 1.29s2.83 6.05 1.29 8.92c-.36.66-.16 1.49.49 1.86 2.3 1.3 4.78 2.34 7.38 3.06.73.2 1.46-.27 1.68-.99.95-3.1 3.83-5.36 7.24-5.36s6.3 2.26 7.24 5.36c.22.73.95 1.2 1.68.99 2.6-.73 5.08-1.76 7.38-3.06.65-.37.84-1.2.49-1.86-1.54-2.86-1.13-6.5 1.29-8.92s6.05-2.83 8.92-1.29c.66.36 1.49.16 1.86-.49 1.34-2.36 2.39-4.9 3.12-7.57.19-.69-.22-1.38-.88-1.63a7.567 7.567 0 0 1-4.93-7.1c0-3.25 2.05-6.02 4.93-7.1.67-.25 1.07-.94.88-1.63z"
      ></path>
      <circle cx="32" cy="32" r="22.96" fill="#6590ab"></circle>
      <circle cx="32" cy="32" r="19.53" fill="#fff"></circle>
      <path
        fill="#324a5e"
        d="M32.97 32h-1.93V20.48a.97.97 0 0 1 1.94 0V32z"
      ></path>
      <path
        fill="#324a5e"
        d="M39.87 39.87c-.38.38-.99.38-1.37 0l-7.19-7.19 1.37-1.37 7.19 7.19c.38.38.38.99 0 1.37z"
      ></path>
      <path
        fill="#ff6e56"
        d="M22.73 41.27a.604.604 0 0 1 0-.86l8.85-8.84.86.86-8.85 8.85c-.24.23-.63.23-.86-.01z"
      ></path>
      <circle cx="32" cy="32" r="2.15" fill="#84dbff"></circle>
      <path
        fill="#acb3ba"
        d="M32 49.8c-.39 0-.71-.32-.71-.71v-1.41c0-.39.32-.71.71-.71.39 0 .71.32.71.71v1.41c0 .39-.32.71-.71.71zm0-32.76c-.39 0-.71-.32-.71-.71v-1.41c0-.39.32-.71.71-.71.39 0 .71.32.71.71v1.41c0 .39-.32.71-.71.71zM49.8 32c0 .39-.32.71-.71.71h-1.41c-.39 0-.71-.32-.71-.71 0-.39.32-.71.71-.71h1.41c.39 0 .71.32.71.71zm-32.76 0c0 .39-.32.71-.71.71h-1.41c-.39 0-.71-.32-.71-.71 0-.39.32-.71.71-.71h1.41c.39 0 .71.32.71.71zM19.42 44.58a.7.7 0 0 1 0-1l1-1a.7.7 0 0 1 1 0 .7.7 0 0 1 0 1l-1 1a.7.7 0 0 1-1 0zm23.16-23.16a.7.7 0 0 1 0-1l1-1a.7.7 0 0 1 1 0 .7.7 0 0 1 0 1l-1 1a.7.7 0 0 1-1 0zM44.58 44.58a.7.7 0 0 1-1 0l-1-1a.7.7 0 0 1 0-1 .7.7 0 0 1 1 0l1 1a.7.7 0 0 1 0 1zM21.42 21.42a.7.7 0 0 1-1 0l-1-1a.7.7 0 0 1 0-1 .7.7 0 0 1 1 0l1 1a.7.7 0 0 1 0 1z"
      ></path>
    </svg>
  );
};

export default Two47Support;
