import React from 'react';

const Testimonial = ({
  profile_picture,
  profile_name,
  testimony,
  position,
}) => {
  return (
    <div className="testimonial-item">
      <div className="testimonial-dp">
        <img class="profile-picture" src={profile_picture} alt="dp" />
      </div>
      <q class="text-wrap">{testimony}</q>
      <div class="name">{profile_name}</div>
      <div class="position-wrap">{position}</div>
    </div>
  );
};

export default Testimonial;
