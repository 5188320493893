import React from "react";

const Support = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 128 128"
      viewBox="0 0 128 128"
      id="business-solution"
    >
      <path
        fill="#fff"
        d="M92.4,30.9c-0.5-0.1-1-0.1-1.5-0.2c-0.7-3.7-2.2-7.1-4.2-10.2c0.3-0.4,0.6-0.8,0.9-1.2
						c0.8-1.1,1.7-2.2,2.5-3.3l-6.2-6.2c-1.1,0.8-2.2,1.6-3.3,2.5c-0.4,0.3-0.8,0.6-1.2,0.9c-3-2-6.5-3.5-10.2-4.2
						c-0.1-0.5-0.1-1-0.2-1.5c-0.2-1.4-0.4-2.7-0.6-4.1h-8.8c-0.2,1.4-0.4,2.7-0.6,4.1c-0.1,0.5-0.1,1-0.2,1.5
						c-3.7,0.7-7.2,2.2-10.2,4.2c-0.4-0.3-0.8-0.6-1.2-0.9c-1.1-0.8-2.2-1.7-3.3-2.5l-6.2,6.2c0.8,1.1,1.6,2.2,2.5,3.3
						c0.3,0.4,0.6,0.8,0.9,1.2c-2,3-3.5,6.5-4.2,10.2c-0.5,0.1-1,0.1-1.5,0.2c-1.4,0.2-2.7,0.4-4.1,0.6v8.8c1.4,0.2,2.7,0.4,4.1,0.6
						c0.5,0.1,1,0.1,1.5,0.2c0.7,3.7,2.2,7.1,4.2,10.2c-0.3,0.4-0.6,0.8-0.9,1.2c-0.8,1.1-1.7,2.2-2.4,3.3l6.2,6.2
						c1.1-0.8,2.2-1.6,3.3-2.4c0.4-0.3,0.8-0.6,1.2-0.9c3,2,6.4,3.5,10.1,4.2c0.1,0.5,0.1,1,0.2,1.5c0.2,1.4,0.4,2.7,0.6,4.1h8.8
						c0.2-1.4,0.4-2.7,0.6-4.1c0.1-0.5,0.1-1,0.2-1.5c3.7-0.7,7.1-2.2,10.1-4.2c0.4,0.3,0.8,0.6,1.2,0.9c1.1,0.8,2.2,1.7,3.3,2.4
						l6.2-6.2c-0.8-1.1-1.6-2.2-2.4-3.3c-0.3-0.4-0.6-0.8-0.9-1.2c2-3,3.5-6.4,4.2-10.1c0.5-0.1,1-0.1,1.5-0.2
						c1.4-0.2,2.7-0.4,4.1-0.6v-8.8C95.1,31.2,93.7,31,92.4,30.9z M72.7,53.1c-0.9,0.4-1.8,0.8-2.7,1.1c-1.9,0.6-3.9,0.9-6,0.9
						c-2.1,0-4.1-0.3-6-0.9c-0.9-0.3-1.9-0.7-2.7-1.1c-3.6-1.8-6.6-4.8-8.4-8.4c-0.5-0.9-0.8-1.8-1.1-2.8c-0.6-1.9-0.9-3.9-0.9-6
						c0-2.1,0.3-4.1,0.9-6c0.3-1,0.7-1.9,1.2-2.8c1.8-3.6,4.8-6.6,8.4-8.4c0.9-0.5,1.8-0.8,2.8-1.2c1.9-0.6,3.9-0.9,6-0.9
						c2.1,0,4.1,0.3,6,0.9c1,0.3,1.9,0.7,2.8,1.2c3.6,1.8,6.6,4.8,8.4,8.4c0.5,0.9,0.8,1.8,1.1,2.8c0.6,1.9,1,3.9,1,6
						c0,2.1-0.3,4.1-0.9,6c-0.3,0.9-0.7,1.9-1.1,2.7C79.3,48.2,76.3,51.2,72.7,53.1z"
      ></path>
      <path
        fill="#ffffff"
        d="M108.7 89.6c-10.1-5-32.3-12.5-32.3-12.5h-1.9l-6.1 8.1 5.7 39.4h42.4c0 0-.3-17-2-27.2C114 94 111.8 91.1 108.7 89.6zM51.6 77.1c0 0-22.2 7.4-32.3 12.5-3.1 1.5-5.2 4.4-5.8 7.8-1.7 10.1-2 27.2-2 27.2h42.4l5.7-39.4-6.1-8.1H51.6z"
      ></path>
    </svg>
  );
};

export default Support;
