import "./header.styles.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../mobileMenu/mobileMenu.component";
import Menu from "../../svg/menu";
import DropMenu from "../dropMenu/dropMenu.component";
import DropDwonArrow from "../../svg/dropdownArrow";
import MobileDropdown from "../mobileMenu/mobileMenuDropdown.component";
import Logo from "../../assets/images/logo.png";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showDropMenu, setShowDropMenu] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  const onMouse = () => {
    setShowDropMenu(!showDropMenu);
  };

  const onMouseLeave = () => {
    setTimeout(() => {
      setShowDropMenu(false);
    }, 2000);
  };

  const handleClose = () => {
    setShowMobileDropdown(false);
    setShowMobileMenu(false);
    setShowDropMenu(false);
  };
  return (
    <div className="main-header">
      <div className="nav">
        {" "}
        <Link to={"/"} className="header-logo">
          <div>
            <img src={Logo} alt="" width={150} />
          </div>
        </Link>
        <div className="nav-list">
          <Link
            to={"/#banner"}
            className="nav-link"
            onMouseOver={() => setShowDropMenu(false)}
          >
            Home
          </Link>
          <Link
            to={"/products"}
            className="nav-link products-dropdown"
            onMouseOver={() => {
              setTimeout(() => {
                setShowDropMenu(true);
              }, 500);
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                setShowDropMenu(false);
              }, 500);
            }}
          >
            Featured Products <DropDwonArrow />
          </Link>
          <Link
            to={"/services"}
            className="nav-link"
            onMouseOver={() => setShowDropMenu(false)}
          >
            Services
          </Link>

          {/* <Link to={"/testimonials"} className="nav-link">
            Testimonials
          </Link> */}
          <Link to={"/about"} className="nav-link">
            About us
          </Link>
          <Link to={"/contact/#contact"} className="nav-link">
            Contact us
          </Link>
        </div>
        <div className="ham-menu">
          <span
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="menu-icon"
          >
            <Menu />
          </span>
          <span className="menu-title">Menu</span>
        </div>
        {showMobileMenu && (
          <MobileMenu
            setShowMobileMenu={setShowMobileMenu}
            setShowMobileDropdown={setShowMobileDropdown}
            handleClose={handleClose}
            showMobileMenu={showMobileMenu}
          />
        )}
        {showDropMenu && (
          <DropMenu
            showDropMenu={showDropMenu}
            setshowDropMenu={setShowDropMenu}
            handleClose={handleClose}
          />
        )}
        {/* {showMobileDropdown && (
          <MobileDropdown setShowMobileDropdown={setShowMobileDropdown} />
        )} */}
        {showMobileDropdown && (
          <MobileDropdown
            setShowMobileDropdown={setShowMobileDropdown}
            setShowMobileMenu={setShowMobileMenu}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
