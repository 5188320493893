import React, { useEffect, useRef } from "react";
import "./card.styles.css";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const ContactCard = ({
  icon,
  communicationMode,
  primaryLabel,
  secondaryLabel,
  primaryInfo,
  secondaryInfo,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
    }
  }, [isInView]);

  return (
    <motion.div
      className="contact-item"
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
      }}
      transition={{
        duration: 1,
        ease: "easeIn",
      }}
      ref={ref}
    >
      <div className="contact-wrap">
        <div className="contact-icon">{icon}</div>
        <div className="contact-header">{communicationMode}</div>
        <div className="primary-info">
          <span className="label">{primaryLabel} </span>{" "}
          <a
            href={
              communicationMode === "Phone"
                ? `tel:+${primaryInfo}`
                : communicationMode === "Email"
                ? `mailto:${primaryInfo}`
                : ""
            }
          >
            {primaryInfo}
          </a>
        </div>
        <div className="secondary-info">
          <span className="label">{secondaryLabel} </span>{" "}
          <a
            href={
              communicationMode === "Phone"
                ? `tel:+${secondaryInfo}`
                : communicationMode === "Email"
                ? `mailto:${secondaryInfo}`
                : ""
            }
          >
            {secondaryInfo}
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactCard;
