import React from "react";
import Banner from "../../components/banner/banner.component";
import HomeCard from "../../components/cards/homeCard.component";
import VideoPlayer, {
  CloudinaryVideoPlayers,
} from "../../components/videoPlayer/videoPlayer.component";
import RightArrow from "../../svg/right-arrow";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Banner />

      <section className="container">
        {" "}
        <HomeCard title="Diagnostic Instruments" color="#22cab0" />
        <HomeCard title="Application Support" color="#555656" />
        <HomeCard title="Technical Support" color="#0090c4" />
      </section>
      <div className="testimonial-video">
        <div className="heading">Our work in Malawi</div>
        <iframe
          src="https://player.cloudinary.com/embed/?cloud_name=dhbe6wtod&public_id=healix/clips/i62lexugjukal0s6vxaf"
          width={600}
          height={700}
          title="testimonical"
        />
        {/* <Link to="/testimonials" className="testimonials-btn">
          More Testimonials
          <RightArrow />
        </Link> */}
      </div>
    </div>
  );
};

export default Home;
