import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 70 70"
      viewBox="0 0 70 70"
      id="phone"
      fill="#ffffff"
    >
      <path d="M30.5 22.1c.4-.4.4-1 0-1.4l-9.4-9.4c-.4-.4-1-.4-1.4 0l-3.2 3.2 10.8 10.8L30.5 22.1zM30.9 39.1c-4.9-4.9-7.2-9.9-5.5-11.8L14.5 16.5l-2 2c-1.5 1.5-1.8 3.7-1.1 6.6 3 11.1 22.4 30.5 33.5 33.5 1 .3 1.9.4 2.7.4 1.6 0 2.9-.5 3.8-1.5l2.1-2.1L42.7 44.7C40.8 46.3 35.7 44 30.9 39.1zM58.7 48.9l-9.4-9.4c-.4-.4-1-.4-1.4 0l-3.2 3.2 10.8 10.8 3.2-3.2C59.1 49.9 59.1 49.3 58.7 48.9z"></path>
    </svg>
  );
};

export default PhoneIcon;
