export const members = [
  {
    name: "Anthony Behan",
    position: " Managing Director",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706866446/healix/Healix%20Team/anthony_otn3nh.jpg",
  },
  {
    name: "Mike Guest",
    position: "Operations Director",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706871517/healix/Healix%20Team/mike_abafla.jpg",
  },
  {
    name: "Odwa Salama",
    position: "Finance & Admin Manager",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706870723/healix/Healix%20Team/WhatsApp_Image_2024-01-31_at_11.06.24_xhb1bz.jpg",
  },
  {
    name: "Nora Kokha",
    position: "Senior Administrator",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706866239/healix/Healix%20Team/WhatsApp_Image_2024-01-31_at_11.07.10_alcjfm.jpg",
  },
  // ghhghgg
  {
    name: "Frank Tandwe",
    position: "Field Service Engineer",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706865930/healix/Healix%20Team/Frank_i2vkxt.jpg",
  },
  {
    name: "Tiwonge Chawinga ",
    position: "Application Specialist",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706865822/healix/Healix%20Team/Tiwonge_yjl3pd.jpg",
  },
  {
    name: "Kappa Kachikowa",
    position: "Field Service Engineer",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706865850/healix/Healix%20Team/Kappa_kvyxyb.jpg",
  },
  {
    name: "Richard Masewo",
    position: "Field Service Engineer",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706865893/healix/Healix%20Team/Richard_nnclca.jpg",
  },
];
