export const products = [
  //========== Hematology  =============
  {
    name: "DxH 520",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706652962/healix/Healix%20products/hem-dxh520-web_glee55.jpg",
    category: "Hematology",
    summary:
      "Small and mighty, the DxH 520 delivers comprehensive quality results with only three reagents and a 17 µL aspiration—ideal for pediatric or difficult draws",
    fullDescription:
      "The powerful, compact DxH 520 is designed to optimize patient care by increasing productivity while reducing operating and overhead costs for low-volume laboratories. Small and mighty, the DxH 520 delivers comprehensive quality results with only three reagents and a 17 µL aspiration—ideal for pediatric or difficult draws. System performance is rounded out with advanced patient and data management tools, and unparalleled reliability—all of which means more time spent on patient care and less time spent on operational tasks.",
  },
  {
    name: "DxH 560 AL",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706652962/healix/Healix%20products/3DxH_560_AL_Hematology_Analyzer563x480_y8stqj.png",
    category: "Hematology",
    summary:
      "The compact 5-part differential autoload hematology analyzer for the low- to mid-volume, laboratory",
    fullDescription:
      "The DxH 560 autoloader system, laboratories can deliver critical first results accurately, with an aspiration of only 17 µL. Experience safety without compromise with continuous load and walkaway capabilities. The DxH 560 AL analyzer provides users optimal safety against exposure to blood- borne pathogens with closed tube aspiration. Easily load up to 50 samples without the need to stop analysis, up to 2x higher capacity than the closest competitor. Laboratories can also choose the open tube mode for even smaller draws and non-cap-pierceable collection tubes. Standardization for tube management is simplified with rule-driven testing.",
  },
  {
    name: "DxH 900",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706652961/healix/Healix%20products/DxH-900_sflrcf.jpg",
    category: "Hematology",
    summary:
      "Discover how the DxH 900 hematology analyzer delivers quality results for physicians and patients, while also streamlining laboratory operations",
    fullDescription:
      "The DxH 900 hematology analyzer empowers hematology decisions through near native-state cellular characterization, ensures predictable costs through a 93% first-pass yield1 and maximizes staff time. With the DxH 900, high-volume laboratories can deliver accurate results, while gaining operational efficiency and managing resources. Reliable, reportable WBC differential results through innovative data collection and analysis. Stronger clinical decision-making through high-quality platelet analysis. Superb anemia assessment due to optimal cellular characterization throughout the red blood cell maturation cycle.Streamlined QA/QC processes through customizable, automated technology with auto-export, daily reminders and remote support. Optimized operations through a seamless workflow, small footprint, intuitive software, easy maintenance and Shortcut Station. One-touch workflow through total laboratory automation Quality results and laboratory efficiency through comprehensive body fluid analysis",
  },
  //========== Microbiology  =============

  {
    name: "DxM MicroScan WalkAway",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681975/healix/Healix%20products/Microbiology/DxM-1096-MicroScan-Walkaway_bjbw25.jpg",
    category: "Microbiology",
    summary:
      "Designed to work in laboratories of every size, MicroScan microbiology systems use a single-panel format that enables scalability. ",
    fullDescription:
      "Ideal for mid- to high-capacity laboratories, 40- and 96-panel capacity models, Automated incubation, test interpretation and reagent control, Conventional overnight ID/AST and/or specialty and rapid ID testing on one instrument.",
  },
  {
    name: "MicroScan autoSCAN-4 system",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681975/healix/Healix%20products/Microbiology/micro-MicroScan-autoSCAN-4-System-web_kaiuyg.jpg",
    category: "Microbiology",
    summary:
      "Designed to work in laboratories of every size, MicroScan microbiology systems use a single-panel format that enables scalability. ",
    fullDescription:
      "Ideal for small-capacity laboratories or backup testing, Automated read of bacterial ID and susceptibility in seconds, Testing for difficult and slow-growing species. Automatic recording and interpretation of instrument results by computerized system.",
  },

  //========== ImmunoChemistry  =============

  {
    name: "The UniCel DxI 600 Access System",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681980/healix/Healix%20products/ImmunoChemistry/Immun-UniCel-DxI-600_ky07rk.jpg",
    category: "ImmunoChemistry",
    summary:
      "The UniCel DxI Access family of systems is designed to meet the clinical and business needs of laboratories of every size by improving workflow efficiencies, reducing operator intervention and increasing productivity.",
    fullDescription:
      "The UniCel DxI 600 Access can run up to 200 tests per hour, fully automated system. They provide fast and accurate results with Beckman Coulter’s proven chemiluminescent technology while utilizing the same reagent packs and the same protocols.",
  },
  {
    name: "Access 2 Immunoassay System",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714128262/healix/Healix%20products/ImmunoChemistry/The_Access_2_yd3qpx.jpg",
    category: "ImmunoChemistry",
    summary:
      "The Access 2 was designed to have the robustness of a reference-lab immunoassay analyzer in the convenient size of a bench-top instrument; giving you greater control over quality, reliability and speed without sacrificing valuable floor space",
    fullDescription:
      "The Access 2 uses slim-line reagent packs that go from box to instrument with ease. The Access reagent kit boxes are easy to store, making the most of your lab’s refrigerator space. And the ease-ofuse reagent carrousel and sample loading allows you to spend moretime with your patients, not an instrument",
  },

  //========== Chemistry  =============
  {
    name: "AU480 Clinical Chemistry System",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681979/healix/Healix%20products/Chemistry/AU480_nud5ok.jpg",
    category: "Chemistry",
    summary:
      "The AU480 is ideal as a primary chemistry analyzer for small- to medium-sized hospitals and laboratories, or as a dedicated specialty chemical or STAT analyzer for larger institutions.",
    fullDescription:
      " With random access throughput of up to 400 photometric tests per hour (up to 800 with electrolytes), 63 on- board parameters tests and user-definable sample handling options, the AU480 delivers efficiency for all your lab needs.",
  },

  //========== Flow Cytometry  =============
  {
    name: "Aquios CL",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681979/healix/Healix%20products/Flow%20Cytometry/Flow-Cytometers-Aquios-CL_vbwifs.png",
    category: "Flow Cytometry",
    summary:
      "Aquios CL is easy to learn, easy to use, ideal for cross-training your lab staff, and represents a breakthrough solution to the most basic operational challenges of flow cytometry.",
    fullDescription:
      "Provide a 24/7 flow cytometry service as the Aquios CL can be run safely by minimally trained users. Minimize the potential for user error inherent in existing systems that require numerous manual, Increase productivity with high-throughput performance that eliminates many of the east efficient features of existing systems. Minimize hands-on time with a fully integrated system that incorporates automated loading, sample preparation, reagent management, barcode scanning, data analysis, and full LIS connectivity.",
  },
  {
    name: "CytoFLEX",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681980/healix/Healix%20products/Flow%20Cytometry/Flow-Cytometry-Cytometers-CytoFLEX_jbzm1q.png",
    category: "Flow Cytometry",
    summary:
      "The CytoFLEX family of flow cytometers is designed to deliver superior performance with ease of operation for research applications.",
    fullDescription:
      "Simplified system setup, data acquisition, analysis, and export of experimental results are integrated into a complete workflow solution with CytExpert software. The CytoFLEX systems feature a compact footprint, integrated detection optics and lasers, and a simplified, highly reliable fluidics system. The CytoFLEX or CytoFLEX S flow cytometer is easily installed.",
  },
  {
    name: "CytoFLEX S",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681979/healix/Healix%20products/Flow%20Cytometry/Flow-Cytometry-Cytometers-CytoFLEX-S-Full-View-2017-05_jfesop.png",
    category: "Flow Cytometry",
    summary:
      "The CytoFLEX S is available as a 2, 3 or 4 laser system and 15 parameters that contain a yellow, 561 nm laser for fruit dye applications",
    fullDescription:
      "The system has an optional 96-well Plate Loader. Together, the CytoFLEX and CytoFLEX S with CytExpert software bring high performance flow cytometry to a variety of researchers with simple to complex needs.",
  },

  {
    name: "DxFlex Flow Cytometer",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681980/healix/Healix%20products/Flow%20Cytometry/Flow-cytometer-DxFLEX-1_gdkagi.jpg",
    category: "Flow Cytometry",
    summary:
      "DxFLEX is a new clinical flow cytometry platform from Beckman Coulter. With advanced sensitivity and intuitive software, DxFLEX makes flow cytometry routine for both novice and expert flow cytometry users and promotes standardization.",
    fullDescription:
      "The DxFLEX Flow Cytometer is designed for clinical applications. It offers performance, compact design, and streamlined installation and operation. System settings, data acquisition, and result exporting functions are integrated into the CytExpert for DxFLEX software workflow. Four configurations are available with up to 3 lasers and 13 detectors. Users can upgrade their configuration at a later date to activate additional channels including more lasers or optical filters. A unique assembly of technologies are used to minimize light loss and therefore maximize sensitivity. A new approach to light management underpins every decision in the design. The result is an instrument with resolving power to separate dim and bright populations. The separation facilitates setting gates and calculating statistics. The DxFLEX flow cytometer is flexible enough to accommodate a variety of assays. Instruments with as few as four and as many as 13 fluorescent detectors are available, with the ability to upgrade as the needs grow. Instrument performance provides the capabilities to resolve populations facilitating complex gating analyses. ",
  },

  //========== Centrifugation  =============
  {
    name: "Microfuge 20 and 20R",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681979/healix/Healix%20products/Centrifugation/Microfuge_20R_s3irhd.png",
    category: "Centrifugation",
    summary:
      "Fast, cool and versatile, our top-of-the-line, high-performance microcentrifuges are the Microfuge 20 Series.",
    fullDescription:
      " Quickly process samples at maximum speeds of up to 15,000 rpm (20,627 x g),  spin more samples per run with a maximum capacity of 36 x 1.5/2.0 mL tubes, save setup time with an easy-to-use interface and user-defined programs for common protocols, safely process hazardous samples with an optional BioCertified* rotor and lower utility costs with increased energy efficiency.",
  },
  {
    name: "Microfuge 16",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681979/healix/Healix%20products/Centrifugation/Microfuge-16_asime5.png",
    category: "Centrifugation",
    summary:
      "Powerful, compact and cold-room compatible. The Microfuge 16 reduces process time and adds flexibility to protocols.",
    fullDescription:
      "Powerful, compact and cold-room compatible. The Microfuge 16 reduces process time and adds flexibility to protocols. Process samples at speeds up to 14,800 rpm (16,163 x g),shorten run times with fast acceleration and deceleration, choose between the 12- or 24-place 1.5/2.0 mL tube rotor to best match your capacity needs. Peace of mind with a maintenance-free motor and safety auto-lock closure system.",
  },

  //========== Blood Gas  =============
  {
    name: "ABL800 basic analyzer",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681980/healix/Healix%20products/%20Blood%20Gas/ABL_800_BASIC_ANALYZER_ikbcf3.png",
    category: "Blood Gas",
    summary:
      "Your ideal choice when looking for efficiency and value in critical care testing. Get the most important critical care parameters, including total hemoglobin and oxygen saturation. Easy to use, the ABL800 BASIC analyzer provides you with fast and reliable results for fast patient treatment.",
    fullDescription:
      "Ideal for high-volume departments, the ABL800 BASIC analyzer has a proven track record of throughput of up to 250 samples per day and is renowned for its high analytical accuracy. So much that most blood gas instruments on the market use the ABL800 series as a reference instrument [1]. With its simple and user-friendly procedures, 97% of current users are either satisfied or completely satisfied with the ease-of-use of the ABL800 BASIC analyzer*. ",
  },

  //========== Molecular  =============
  {
    name: "Panther System",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706681980/healix/Healix%20products/Molecular/Panther.jpg_large_yexgee.jpg",
    category: "Molecular",
    summary:
      "The Panther system has been revolutionising molecular testing since 2010 by providing labs with the ability to consolidate their assay menu on a fully-automated system with proven performance and features that drive value and enable tangible and immediate savings.",
    fullDescription:
      "Broad assay menu on a single platform allows consolidation and the ability to run more tests per FTE. Full automation allows samples to be loaded at the end of the day and run labour free after hours. Ability to run multiple test orders from the same patient sample at the same time expedites results. Ability to load primary sample tubes eliminates manual transfer of specimen. Flexible sample loading provides control over workflow by either random access or batch processing. Programmable/automated maintenance allows tasks to run labour free during non-operational hours. STAT samples can be loaded at any time, allowing urgent samples to be prioritised. Specialised reports to automate tracking and trending of QC and Consumables designed to reduce waste (tubes vs plate) and included in price-per-test provides transparency.",
  },
  //========== Water Purification  =============
  // {
  //   name: 'er',
  //   image:
  //     'https://res.cloudinary.com/dhbe6wtod/image/upload/v1705236116/healix/m1_zvrwv6.png',
  //   category: 'Water Purification',
  //   summary: 'we',
  //   fullDescription: 'we',
  // },
];
