import React, { useState } from "react";
import "./contact.styles.css";
import ContactCard from "../../components/cards/contactCard.component";
import PhoneIcon from "../../svg/phone";
import Email from "../../svg/email";
import AddressIcon from "../../svg/location";
import { Form, Formik } from "formik";
import InputComponent from "../../components/inputs/inputField.component";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TextArea from "../../components/inputs/textArea.component";
import PulseLoader from "react-spinners/PulseLoader";
import { getError } from "../../helpers/errors.helper";
const ContactUs = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const contactInfo = {
    fullName: "",
    email: "",
    mobile: "",
    message: "",
  };
  const [contact, setContact] = useState(contactInfo);
  const { fullName, email, mobile, message } = contact;
  const handleContact = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleValidation = Yup.object({
    fullName: Yup.string()
      .required("Name is required")
      .min(2, "name but have more than 2 character")
      .max(16, "name must be less than 16 characters")
      .matches(/[aA-zZ]+$/, "Number and special characters are not allowed"),
    email: Yup.string()
      .required("Email Address is required.")
      .email("Invalid email address"),
    mobile: Yup.string().required("Phone  is required."),
    message: Yup.string().required("message is required").min(20).max(500),
  });
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://jacobmbenderamailserviceapi.onrender.com/contactUs",
        {
          fullName,
          email,
          mobile,
          message,
        }
      );
      setError("");
      setSuccess(data.message);

      setTimeout(() => {
        navigate("/");
      }, 2000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(getError(err));
    }
  };
  return (
    <div id="contact" className="contact">
      <div className="contact-wrapper">
        <div className="contact-header page-header">Contact Us </div>
        <div className="content">
          <ContactCard
            icon={<PhoneIcon />}
            communicationMode="Phone"
            primaryLabel="Mobile"
            secondaryLabel="Tel"
            primaryInfo="+265 888 729 116"
            secondaryInfo="+265 886 790 05"
          />
          <ContactCard
            icon={<Email />}
            communicationMode="Email"
            primaryInfo="support@healixmw.tech"
            secondaryInfo="nora@healixmw.tech"
          />

          <ContactCard
            icon={<AddressIcon />}
            communicationMode="Address"
            primaryInfo="P. O. Box 3105"
            secondaryInfo="Blantyre, Malawi"
          />
        </div>

        <div className="form-faq">
          <div className="faq">
            <div className="faq-wrap">
              <div id="faqs" className="faq-header">
                FAQs
              </div>
              <Accordion>
                <AccordionItem header="Why should I use Healix Medical Ltd?">
                  Healix works with our clients to provide the best in class
                  diagnostic solutions. Healix has authorized distributor
                  partnerships with internationally recognized brands, and
                  Healix Medical has trained our technical and application
                  specialist teams at the highest level with these companies.
                  Through using the products and services that Healix provides
                  you can reduce your long term costs of diagnostic services.
                </AccordionItem>

                <AccordionItem header="Do you provide any health care services?">
                  No, Healix Medical provides diagnostic equipment, technical
                  support, training and solutions to research labs, public and
                  private hospitals and others. Healix Medical represent
                  internationally recognized companies in Malawi.
                </AccordionItem>

                <AccordionItem header="In which areas do you offer your services?">
                  Healix Medical covers the whole of Malawi.
                </AccordionItem>
                <AccordionItem header="How can I get a quote or feedback for services or solutions offered by Healix?">
                  Our services are closer than you think. You can go to the 'Get
                  Quote' section, fill out the form and click 'Submit'. Once you
                  have submitted you will receive a response from our customer
                  service team within 48 hours
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div id="contact-form" className="contact-form">
            <div className="form-header ">Contact Form</div>
            <Formik
              enableReinitialize
              validationSchema={handleValidation}
              initialValues={{ fullName, mobile, email, message }}
              onSubmit={() => {
                handleSubmit();
              }}
            >
              {(form) => (
                <Form className="form-inputs" s>
                  {" "}
                  <InputComponent
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    onChange={handleContact}
                  />
                  <InputComponent
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleContact}
                  />
                  <InputComponent
                    type="text"
                    name="mobile"
                    placeholder="Phone Number"
                    onChange={handleContact}
                  />
                  <TextArea
                    name="message"
                    placeholder="Type your message"
                    onChange={handleContact}
                  ></TextArea>
                  {error && <div className="input-error">{error}</div>}
                  {success && <div className="input-success">{success}</div>}
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                  <PulseLoader
                    color="#36d7b7"
                    loading={loading}
                    size={15}
                    speedMultiplier={1}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
