import { ErrorMessage, useField } from 'formik';
import React from 'react';
import './inputs.styles.css';

const InputComponent = ({ placeholder, bottom, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="input-wrap">
      <input
        className={meta.touched && meta.error ? 'input-error-border' : ''}
        type={field.type}
        name={field.name}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <div className="input-error">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </div>
      )}
    </div>
  );
};

export default InputComponent;
