import React, { useEffect, useRef } from "react";
import MRi from "../../svg/services/mrl";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const ServicesComponent = ({
  serviceImage,
  serviceIcon,
  serviceName,
  serviceDescription,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView]);

  return (
    <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        className="services-item"
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, delay: 0.5 }}
      >
        {/* <motion.div
          variants={{
            hidden: { left: 0 },
            visible: { left: "100%" },
          }}
          initial="hidden"
          animate={slideControls}
          transition={{ duration: 1, ease: "easeIn" }}
          style={{
            height: "30px",
            position: "absolute",
            top: 4,
            bottom: 4,
            left: 0,
            right: 0,
            border: "1px solid #2c946549",
            background: "#57e0ae",
            zIndex: 20,
          }}
        ></motion.div> */}
        <div className="services-image">
          <img src={serviceImage} alt={serviceName} />
        </div>
        <motion.div
          className="services-summary"
          variants={{
            hidden: { x: 75 },
            visible: { x: 0 },
          }}
          initial="hidden"
          animate={slideControls}
          transition={{ duration: 1, ease: "easeIn" }}
        >
          <div className="service-icon">{serviceIcon}</div>
          <div className="services-name">{serviceName}</div>
          <p>{serviceDescription}</p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ServicesComponent;
