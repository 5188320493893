import "./services.styles.css";
import React from "react";
import ServicesComponent from "../../components/services/services.component";
import { Allservices } from "../../data/allServices";

const Services = () => {
  return (
    <div id="services" className="services">
      <div className="page-header">Services</div>
      <div className="services-wrap">
        {Allservices.map((service) => (
          <ServicesComponent
            serviceName={service.serviceName}
            serviceImage={service.serviceImage}
            serviceIcon={<service.serviceIcon />}
            serviceDescription={service.serviceDescription}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
