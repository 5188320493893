import React from "react";

const Time = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="time">
      <path
        fill="#87d1ee"
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm5,15H16a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0v8h4a1,1,0,0,1,0,2Z"
      ></path>
    </svg>
  );
};

export default Time;
