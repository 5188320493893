export const partners = [
  {
    id: "",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714112731/healix/partners/thermo-fisher-scientific-logo-5279x1344_nsx5ln.png",
    alt: "",
    caption: "Quality Controls",
    link: "https://www.thermofisher.com/mw/en/home.html",
  },
  {
    id: "",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714112734/healix/partners/Hologic_zczdtx.png",
    alt: "",
    caption: "Molecular Diagnostics",
    link: "https://www.hologic.com/molecular-diagnostics",
  },
  {
    id: "",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714112730/healix/partners/beckmn_kusac5.png",
    alt: "",
    caption: "Clinical Diagnostics",
    link: "https://www.beckmancoulter.com/",
  },
  {
    id: "",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714112730/healix/partners/beckmn_kusac5.png",
    alt: "",
    caption: "Life Sciences",
    link: "https://www.beckmancoulter.com/about-beckman-coulter/life-sciences",
  },
  {
    id: "elga",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714119743/healix/partners/elga-labwater-2.png",
    alt: "",
    caption: "Purification",
    link: "https://www.anz.veoliawatertechnologies.com/solutions/services/elga-ultrapure-laboratory-water",
  },
  {
    id: "radiometer",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1714119456/healix/partners/radiometer-medical-2.png",
    alt: "",
    caption: "Blood gas",
    link: "https://www.radiometer.com/en/products/blood-gas-testing",
  },
];
