import React from 'react';

const RightArrow = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      viewBox="0 0 512 512"
      id="right-arrow"
      fill={fill}
    >
      <path d="M160 128.4L192.3 96 352 256 192.3 416 160 383.6 287.3 256z"></path>
    </svg>
  );
};

export default RightArrow;
