import React from "react";

const Emaill = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.5 57.5" id="email">
      <g>
        <path
          fill="#b76a8a"
          d="M68.5 11.5h-57v41a5 5 0 0 0 5 5h52a5 5 0 0 0 5-5v-36a5 5 0 0 0-5-5z"
          opacity=".15"
        ></path>
        <rect
          width="62"
          height="46"
          x="2.5"
          y="2.5"
          fill="none"
          stroke="#6a80b9"
          stroke-miterlimit="10"
          stroke-width="5"
          rx="2"
          ry="2"
        ></rect>
        <path
          fill="none"
          stroke="#6a80b9"
          stroke-miterlimit="10"
          stroke-width="2"
          d="m2.5 12.5 29.39 21.81a2.87 2.87 0 0 0 3.21 0L64.5 12.5"
        ></path>
      </g>
    </svg>
  );
};

export default Emaill;
