import "./dropMenu.styles.css";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { products } from "../../data/products";
import useClickOutside from "../../helpers/clickOutside.helper";

const DropMenu = ({ showDropMenu, setshowDropMenu, handleClose }) => {
  const dropMenu = useRef(null);
  useClickOutside(dropMenu, () => {
    setshowDropMenu(false);
  });

  const handleDropMenu = () => {
    setshowDropMenu(false);
  };
  return (
    <>
      {showDropMenu && (
        <div
          className="drop-menu"
          onMouseEnter={() => {
            setTimeout(() => {
              setshowDropMenu(true);
            }, 500);
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setshowDropMenu(false);
            }, 500);
          }}
          ref={dropMenu}
        >
          <ul className="submenu">
            <li className="list-group">
              <h5>
                <Link
                  to={"products/hematology"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  Hematology
                </Link>
              </h5>

              <ul>
                <li to="/products/" onClick={handleClose}>
                  {" "}
                  <Link to={"/product/DxH 520"} onClick={handleClose}>
                    DxH 520
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link to={"/product/DxH 560 AL"} onClick={handleClose}>
                    DxH 560 AL
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link to={"/product/DxH 900"} onClick={handleClose}>
                    DxH 900
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to={"products/microbiology"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  Microbiology
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"/product/DxM MicroScan WalkAway"}
                    onClick={handleClose}
                  >
                    DxM MicroScan WalkAway
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"/product/MicroScan autoSCAN-4 system"}
                    onClick={handleClose}
                  >
                    MicroScan autoSCAN-4 system
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to={"/products/immunochemistry"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  {" "}
                  ImmunoChemistry
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"product/The UniCel DxI 600 Access System"}
                    onClick={handleClose}
                  >
                    The UniCel DxI 600 Access System
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"product/Access 2 Immunoassay System"}
                    onClick={handleClose}
                  >
                    Access 2 Immunoassay System
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to={"/products/chemistry"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  {" "}
                  Chemistry
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"/product/AU480 Clinical Chemistry System"}
                    onClick={handleClose}
                  >
                    AU480 Clinical Chemistry System{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to={"/products/flow cytometry"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  {" "}
                  Flow Cytometry
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link to={"product/Aquios CL"} onClick={handleClose}>
                    Aquios CL
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link to={"/product/CytoFLEX"} onClick={handleClose}>
                    CytoFLEX
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link to={"product/CytoFLEX S"} onClick={handleClose}>
                    CytoFLEX S
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"/product/DxFlex Flow Cytometer"}
                    onClick={handleClose}
                  >
                    DxFlex Flow Cytometer
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to={"/products/centrifugation"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  {" "}
                  Centrifugation
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"product/Microfuge 20 and 20R"}
                    onClick={handleClose}
                  >
                    Microfuge 20 and 20R
                  </Link>
                </li>
                <li to="/products/">
                  {" "}
                  <Link to={"/product/Microfuge 16"} onClick={handleClose}>
                    Microfuge 16
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to="/products/blood gas"
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  {" "}
                  Blood Gas
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link
                    to={"/product/ABL800 basic analyzer"}
                    onClick={handleClose}
                  >
                    {" "}
                    ABL800 basic analyzer
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-group">
              <h5>
                <Link
                  to={"/products/molecular"}
                  className="nav-link-header"
                  onClick={handleClose}
                >
                  {" "}
                  Molecular
                </Link>
              </h5>
              <ul>
                <li to="/products/">
                  {" "}
                  <Link to={"/product/Panther System"} onClick={handleClose}>
                    The Panther system
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="list-group">
          <h5>
            <Link
              to={'/products/water purification'}
              className="nav-link-header"
            >
              {' '}
              Water Purification
            </Link>
          </h5>
          <ul>
            <li to="/products/">
              {' '}
              <Link>Item</Link>
            </li>
            <li to="/products/">
              {' '}
              <Link>Item</Link>
            </li>
          </ul>
        </li> */}
          </ul>
        </div>
      )}
    </>
  );
};

export default DropMenu;
