import React from "react";
import PersonIcon from "../../svg/person";
import "./card.styles.css";
import { Link } from "react-router-dom";

const ServiceCard = ({ serviceIcon, service, serviceDescription }) => {
  return (
    <Link to="/services" className="service-card">
      <div className="service-icon">{serviceIcon}</div>
      <div className="contents">
        <div className="service-title">{service}</div>
        {/* <p className="service-text">{serviceDescription}</p> */}
      </div>
    </Link>
  );
};

export default ServiceCard;
