import PersonIcon from "../svg/person";
import PlusIcon from "../svg/plus";
import Research from "../svg/services/research";
import Support from "../svg/services/support";
import Training from "../svg/services/training";
import ShieldIcon from "../svg/shield";
import Checkup from "./checkup";

export const services = [
  {
    icon: Checkup,
    service: "Innovative Diagnostic Solutions",
    text: "Healix Medical provides diagnostic instruments to research labs, public and private hospitals and others.  Healix Medical represents internationally recognized companies in Malawi",
  },
  {
    icon: Support,
    service: "Technical Support",
    text: "Healix Medical provides technical support to research labs, public and private hospitals and others.  Healix Medical represents internationally recognized companies in Malawi.",
  },
  {
    icon: Training,
    service: "Training",
    text: "Healix Medical provides training to research labs, public and private hospitals and others.  Healix Medical represents internationally recognized companies in Malawi.",
  },
];
