import { Link, useParams } from "react-router-dom";
import "./product.styles.css";
import React from "react";
import ProductDetailComponent from "../../components/product/productDetail.component";
import { products } from "../../data/products";

const Product = () => {
  const params = useParams();
  const { productName } = params;
  return (
    <div className="view-product">
      <div className="page-header">Product</div>
      {/* <ProductDetailComponent /> */}
      {products
        .filter((product) => product.name.includes(productName))
        .map((filteredProduct, index) => (
          <ProductDetailComponent
            key={index}
            productName={filteredProduct.name}
            productImage={filteredProduct.image}
            productSummary={filteredProduct.summary}
            productFullText={filteredProduct.fullDescription}
          />
        ))}
    </div>
  );
};

export default Product;
