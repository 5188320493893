import PlusIcon from "../svg/plus";
import Two47Support from "../svg/services/247support";
import AppSupport from "../svg/services/appSupport";
import microscope from "../svg/services/microscope";
import TechSupport from "../svg/services/techSupport";

export const Allservices = [
  {
    serviceName: "Diagnostic Instruments",
    serviceImage:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622389/healix/official%20photos/INF09338_cxjk99.jpg",
    serviceIcon: microscope,
    serviceDescription:
      "Healix Medical provides a comprehensive and cost effective solution to all diagnostic and medical related equipment and supplies with the added focus on not compromising on quality.The key personnel of Healix Medical have a combined experience of over forty years in the diagnostic and medical field within Malawi, giving them the understanding on what is needed to ensure the successful implementation of projects providing the greatest value to the customer.",
  },
  {
    serviceName: "Technical Support ",
    serviceImage:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622369/healix/official%20photos/INF09269_dr04ef.jpg",
    serviceIcon: TechSupport,
    serviceDescription:
      "Healix Medical provides technical support to research labs, public and private hospitals and others.  Healix Medical represents internationally recognized companies in Malawi. Engineers have been trained by our principles and Healix medical is looking to further enhance these capabilities in the future. In addition, our clients do have direct access to our principles who’s application specialists are available where needed.",
  },
  {
    serviceName: "Application support",
    serviceImage:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622367/healix/official%20photos/INF09255_i6j5qk.jpg",
    serviceIcon: AppSupport,
    serviceDescription:
      "Healix Medical provides application support on all instruments supplied. Application support is provided locally and in conjunction with our principle manufacturers.",
  },
  // {
  //   serviceName: '24/7 Services and support ',
  //   serviceImage:
  //     'https://res.cloudinary.com/dhbe6wtod/image/upload/v1705236116/healix/m1_zvrwv6.png',
  //   serviceIcon: Two47Support,
  //   serviceDescription:
  //     'Lorem ipsum dolor sit amet consectetur. Sed pulvinar ornare at scelerisque pellentesque auctor lorem nisi mauris. Magna. Lorem ipsum dolor sit amet consectetur. Sed pulvinar ornare at scelerisque pellentesque auctor lorem nisi mauris. Magna.Lorem ipsum dolor sit amet consectetur. Sed pulvinar ornare at scelerisque pellentesque auctor lorem nisi mauris. Magna.Lorem ipsum dolor sit amet consectetur. Sed pulvinar ornare at scelerisque pellentesque auctor lorem nisi mauris. Magna.',
  // },
  {
    serviceName: "Training",
    serviceImage:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1706622391/healix/official%20photos/INF09329_rlrldu.jpg",
    serviceIcon: Two47Support,
    serviceDescription:
      "On all equipment supplied, end user training is provided at site.",
  },
];
