import React, { useEffect, useRef } from "react";
import "./productCards.styles.css";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation, delay } from "framer-motion";
const ProductItem = ({ category, name, productImage }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <motion.div
      className="product-item"
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1 }}
      ref={ref}
    >
      <div className="product-img">
        <img src={productImage} alt="productPic" />
      </div>
      <span className="category-name">{category}</span>

      <Link to={`/product/${name}`}>
        <span className="product-name">{name}</span>
      </Link>

      <Link to={`/product/${name}`} className="button">
        View Product
      </Link>
    </motion.div>
  );
};

export default ProductItem;
