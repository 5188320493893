import React from "react";

const microscope = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="microscope">
      <path
        fill="#8fc96b"
        d="M17 18.53v-.45A1.13 1.13 0 0 0 15.83 17h-2.48v-1.62a.22.22 0 0 0-.22-.23h-.68v-.45h2.93a.22.22 0 0 0 .22-.22v-.9a.22.22 0 0 0-.22-.23h-4.06a.22.22 0 0 0-.22.23v1.57H10a3.06 3.06 0 0 1-1.42-2.47A2.66 2.66 0 0 1 10 10.22a1.11 1.11 0 0 0 1.5.24l.58.57a.21.21 0 0 0 .31 0l.07-.06.74.74a.22.22 0 0 0 .32 0l.9-.9a.22.22 0 0 0 0-.32l-.74-.74.06-.07a.22.22 0 0 0 0-.32l-3.6-3.6a.24.24 0 0 0-.32 0l-.06.07-.52-.52a.22.22 0 0 0-.32 0l-.9.9a.24.24 0 0 0 0 .32l.52.52-.07.06a.24.24 0 0 0 0 .32l1.41 1.42H8.17a.22.22 0 0 0-.17.22v.82a4.26 4.26 0 0 0-.9 2.79 4.25 4.25 0 0 0 .9 2.78v3.07a.22.22 0 0 0 .22.22h8.56a.22.22 0 0 0 .22-.22ZM9.43 6.15l-.58.58-.36-.36.58-.58Zm3.34 4.5.58-.58.58.58-.58.58ZM10.2 9.52a.68.68 0 1 1 .67.68.67.67 0 0 1-.67-.68ZM8.94 7.27l1-1 3.29 3.28-1 1-.42-.42a1.11 1.11 0 0 0-.14-1.41 1.12 1.12 0 0 0-1.42-.14ZM8.4 9.75V9.3h1.37a1.14 1.14 0 0 0 0 .45Zm.91 5.4h-1a3.87 3.87 0 0 1-.78-2.47 3.88 3.88 0 0 1 .78-2.48h1a3.17 3.17 0 0 0-1.07 2.48 3.37 3.37 0 0 0 1.07 2.47Zm2.24-.9v-.45h3.6v.45Zm.45.45v1.58a.22.22 0 0 1-.23.22.22.22 0 0 1-.22-.22V14.7Zm.9 2.3H8.4v-1.4h2.7v.68a.68.68 0 0 0 1.35 0v-.68h.45Zm3.6 1.35H8.4v-.9h7.43a.67.67 0 0 1 .67.68Z"
      ></path>
      <path
        fill="#0a7abe"
        d="M9.3 16.5h-.45v-.45h.45zm.9 0h-.45v-.45h.45z"
      ></path>
      <path
        fill="#0a7abe"
        d="M12 24a12 12 0 0 1 0-24 11.86 11.86 0 0 1 7.35 2.52.55.55 0 0 1 .1.78.56.56 0 0 1-.78.1 10.87 10.87 0 1 0 1.93 1.93.56.56 0 0 1 .1-.78.55.55 0 0 1 .78.1A11.86 11.86 0 0 1 24 12a12 12 0 0 1-12 12Z"
      ></path>
    </svg>
  );
};

export default microscope;
