import React from 'react';

const DropDwonArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 64 64"
      viewBox="0 0 64 64"
      id="arrow"
      width={20}
    >
      <path
        fill="#134563"
        d="m-218.7-308.6 2-2 11.7 11.8 11.7-11.8 2 2-13.7 13.7-13.7-13.7"
        transform="translate(237 335)"
      ></path>
    </svg>
  );
};

export default DropDwonArrow;
